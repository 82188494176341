import React, { Component } from "react";
import Helmet from "react-helmet"
import Favicon from "../imagenes/favicon.png"
import ShareImg from "../imagenes/shareImg.jpg"

import { StaticQuery, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import ScrollToTop from "react-scroll-to-top";
import Header from "./en/header"
import Footer from "./en/footer"
const objectGraphql = graphql`
{
  allStrapiSeo(filter: {locale: {eq: "en"}}) {
    nodes {
      meta
    }
  }
}
`

class Layout extends Component {


  parserHtml = (data) => {

    return data.allStrapiSeo.nodes.map((item) => {
      return (
        ReactHtmlParser(item.meta)
      )
    })

  }
  capitalizarPrimeraLetra(str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);

    } else {
      return "Home"
    }
  }
  nameTab = (name) => {
    let path = this.props.props.location.href;
    if (name) {
      return (

        name + ' | Centinela'
      )
    } else {


      if (path) {


        if (path.split("/")[3] === "") {
          return (
            ' Home | Centinela'

          )

        } else {
          return (
            this.capitalizarPrimeraLetra(path.split("/")[4]) + ' | Centinela'
          )
        }
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>

            <Helmet defer={false} >

              {this.parserHtml(data)}

              <link rel="shortcut icon" href={Favicon} type="image/png" data-react-helmet="true" />
              <title>{this.nameTab(this.props.nameTab)}</title>
              <meta property="og:title" content={this.nameTab(this.props.nameTab)} />
              <meta property="og:type"  content="article" />
              <meta property="og:image:width" content="828" />
              <meta property="og:image:height" content="450" />
              <meta property="og:image" content={this.props.imgUrl ?  this.props.imgUrl : ShareImg} />

              <meta property="og:url" content={this.props.props.location.href} />
              <meta property="og:site_name" content="Centinela" />
            </Helmet>
            {this.props.props && <Header traduction={this.props.traduction} init={this.props.init} props={this.props.props} />}
            {this.props.children}
            <div className="scroll__top">
              <ScrollToTop smooth component={
                <i className="fa fa-sort-up" aria-hidden="true"></i>
              } /></div>

            <Footer /> </React.Fragment>
        )}
      />

    )
  }
}


export default Layout
