import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import { Navbar, Nav } from 'react-bootstrap';
import { Link} from "gatsby"
import Traduction from "./tradution"

const objectGraphql = graphql`
{
    allStrapiMenu1S(filter: {locale: {eq: "en"}}) {
        nodes {
          id
          nombre
          url
        }
      }
      allStrapiMenu2S(filter: {locale: {eq: "en"}}) {
        nodes {
          nombre
          id
          url
        }
      }
      strapiSeo {
        logo1 {
          id
          url
        }
      }
}

`

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { locale: 'en' };

        this.menu1 = this.menu1.bind(this);
        this.menu2 = this.menu2.bind(this);
    }
    menu1 = (data) => {
        let array=  data.allStrapiMenu1S.nodes.sort(function(a, b) {
            return a.id.split('_')[1]-b.id.split('_')[1]; 
        });
        return array.map((item) => {
            const menuURl=(item.url).split('&');
            if (menuURl[1]) {
                // console.log(menuURl);
                return (
                    <li key={item.id}><Link to={menuURl[0]} >{item.nombre}</Link></li>
    
                )
            } else {
                return (
                    <li key={item.id}><Link to={item.url} target="_blank">{item.nombre}</Link></li>
    
                )
            }
           
        })
        

    }

    menu2 = (data) => {
        let array=  data.allStrapiMenu2S.nodes.sort(function(a, b) {
            return a.id.split('_')[1]-b.id.split('_')[1]; 
        });
        
        return array.map((item) => {
            const menuURl=(item.url).split('&');
            if (menuURl[1]) {
                // console.log(menuURl);
                return (
                    <li key={item.id}><Link to={menuURl[0]} >{item.nombre}</Link></li>
    
                )
            } else {
                return (
                    <li key={item.id}><Link to={item.url} target="_blank">{item.nombre}</Link></li>
    
                )
            }
        })

    }
   


    render() {
        
        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <header className={this.props.init}>
                        <section>
                            <div className="container">
                                <div className="row" style={{ position: "relative" }}>
                                    <div className="idioma">
                                       <Traduction traduction={this.props.traduction} data={data}  init={this.props.init} props={this.props.props}/>

                                    </div>

                                    <div className="col-lg-3 col-xl-4 logo">
                                        <h1><Link style={{backgroundImage:"url(" + data.strapiSeo.logo1[0].url + ")" }} to="/en">{this.props.props.location.href}</Link></h1>
                                    </div>
                                    <div className="col-lg-9 col-xl-8 m-none">
                                        <nav>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul id="menu2" className="submenu">
                                                        {this.menu1(data)}

                                                    </ul>
                                                </div>
                                                <div className="col-md-12">
                                                    <ul id="menu1" className="menu2">
                                                        {this.menu2(data)}
                                                    </ul>                            </div>
                                            </div>
                                        </nav>
                                    </div>

                                    <div className="col-12 m-menu">
                                        <Navbar expand="lg">
                                            <Navbar.Toggle aria-controls="basic-navbar-nav">
                                                <i className="fa fa-bars"></i> Navigation
                                            </Navbar.Toggle>

                                            <Navbar.Collapse id="basic-navbar-nav">
                                                <Nav className="nav mr-auto">
                                                    <ul className="navbar-nav" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {this.menu1(data)}
                                                    </ul>
                                                    <ul className="navbar-nav" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {this.menu2(data)}
                                                    </ul>

                                                </Nav>

                                            </Navbar.Collapse>
                                        </Navbar>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </header>

                )}
            />



        )
    }
}


export default Header
