import React, { Component } from "react";
import { navigate } from "gatsby"


class Traduction extends Component {

    constructor(props) {
        super(props);
        this.state = { locale: 'en' };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ locale: event.target.value });
        let array1 = this.props.data.allStrapiMenu1S.nodes.sort(function (a, b) {
            return a.id.split('_')[1] - b.id.split('_')[1];
        });
        let array2 = this.props.data.allStrapiMenu2S.nodes.sort(function (a, b) {
            return a.id.split('_')[1] - b.id.split('_')[1];
        });
        let array3 = [{url:'/en/legal_notification&/es/aviso-legal'}]

        array2 = array2.concat(array3);
        array1 = array1.concat(array2);
        let includeMenu = []

        array1.map((item) => {
            const menuURl = (item.url).split('&');
            if (menuURl[1]) {
                if (this.props.props.location.href.includes(menuURl[0])) {
                    if (((this.props.props.location.href.split("/")[3]) === event.target.value)) {
                        includeMenu.push(menuURl[0])

                    } else {
                        includeMenu.push(menuURl[1])

                    }
                }
            }

        })
        console.log(includeMenu, 'aaaaaaaaaaaaa');
        if (includeMenu.length > 0) {
            navigate(includeMenu[0])
        } else {
            let path = this.props.props.location.pathname.split("/");
            console.log(this.props.props.location.href, this.props.props.location.href.split("/")[3], event.target.value);
            if ((this.props.props.location.href.split("/")[3]) === event.target.value) {
                console.log('mismo');
            } else if (this.props.props.location.href.split("/")[3] === "") {
                console.log('aun no select');
                navigate('/' + event.target.value)
            } else {
                path[1] = event.target.value;

                console.log('diferente', path.join("/"));
                navigate(path.join("/"))

            }
        }

    }


    render() {
        let w_locale = this.props.props.location.href;
        let route_locate = []
        if (w_locale) {
            route_locate = w_locale.split("/");
        }
        return (
            <React.Fragment>
                <select defaultValue={this.state.value} onChange={this.handleChange} className="custom-select mb-2 mr-sm-2 mb-sm-0" id="lang-dropdown-select-language" style={{ width: "100px" }} name="lang_dropdown_select">
                  <option value="es" defaultValue="es" selected={false}>Español</option>
                    <option value="en" defaultValue="en" selected={true } >English</option>
                </select>
            </React.Fragment>
        )

    }
}

Traduction.defaultProps = {
    traduction: true
}

export default Traduction
