import React, { Component } from "react";

import Moment from "moment";
import CatPag from "./tag_en_pag"





class Category extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalItems: [],
        };
    }

    componentDidMount() {
        this.filterNew()
    }

    filterNew() {
        const array = this.props.data;
        let path = this.props.props.location.pathname.split("/");
        console.log(path);
        let filterPath = path[path.length - 1];
        if (filterPath === "") {
            filterPath = path[path.length - 2];
        }
        let filterArray = []
        array.allStrapiNoticias.edges.forEach(element => {
            console.log(element);
            element.node.Tags.forEach(item => {
                console.log(item.nombre, filterPath);

                let namePath=item.nombre.replace( /[^-A-Za-z0-9]+/g, '-' ).toLowerCase();

                if (namePath === filterPath) {
                    filterArray.push(element)
                }
            });
        });

        console.log(filterArray);
        const sortedArray = filterArray.sort((a, b) => new Moment(b.node.fecha).format('YYYYMMDD') - new Moment(a.node.fecha).format('YYYYMMDD'))

        console.log(sortedArray,path);
        this.setState({ totalItems: sortedArray });

    }

    render() {
        return (
            <React.Fragment>
                <CatPag  props={this.props.props} totalItems={this.state.totalItems}/>
            </React.Fragment>
        );
    }
}

export default Category
